//const OtherComponent = React.lazy(() => import('./OtherComponent'));
import React, { useState, /*lazy,*/ Fragment, Suspense, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import Popover from '@material-ui/core/Popover';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
//import Button from '@material-ui/core/Button';
import * as MyTable from "components/Incomes/table";
import ErrorBoundary from 'components/Helpers/Fuse';
import UserContext from 'components/Auth/UserContext';
import Notification from 'components/Shared/Notification';


const fetchGet = new FetchWithAuth();

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 650,
      fontSize: 14
    },
    tableButton: {
        maxWidth: 150,
      },
    tableDate: {
        minWidth: 200,
    },
    tableCurrency: {
        minWidth: 100,
    },
    typography: {
        padding: theme.spacing(2),
      },
  }));

const testincome = {
    "2020-12": [
         {
            ID: "id1",
            Income: 1111.11,
            Date: "2020-12-25T00:00:00",
            Currency: "UAH",
            Comment: "Comment1",
            Cash: false
        },
        {
            ID: "id2",
            Income: 2222.22,
            Date: "2020-12-13T00:00:00",
            Currency: "USD",
            Comment: "Comment2",
            Cash: true
        }
    ],
    "2020-11": [
        {
            ID: "id3",
            Income: 3333.33,
            Date: "2020-11-13T00:00:00",
            Currency: "USD",
            Comment: "Comment2",
            Cash: false
        }
    ]
}

export default function Incomes() {
    const [respdata, setRespdata] = useState({});
    const [ids, setIds] = useState([]);
    const [edits, setEdits] = useState([]);
    const [edits1, setEdits1] = useState({});
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newinc, setNewinc] = React.useState({
        ID: '',
        Date: new Date().toISOString().slice(0, 10),
        Income: '',
        Currency: 'UAH',
        Comment: '',
        Cash: false
    });
    const open = Boolean(anchorEl);
    const { user } = useContext(UserContext);
    const anonymousNotificationMessage = "Для перегляду або вводу інформації потрібно авторизуватися. Дані наведені нижче подані для прикладу"
    const [retrieveStatus, setRetrieveStatus] = useState(anonymousNotificationMessage);
    const [hiddenNotification, setHiddenNotification] = useState(false);

    const id = open ? 'simple-popover' : undefined;

    var incomes = {};

    // fetch incomes from the server
    //async function handleSubmit(event) {
    //    event.preventDefault();
    //    incomes = await fetchGet.fetchWithGet("/api/incomes", null);
    //    console.log(JSON.parse(incomes));
    //    setRespdata(JSON.parse(incomes));
    //    return incomes;
    //}

    async function GetData() {
        incomes = await fetchGet.fetchWithGet("/api/incomes", null);
        console.log(JSON.parse(incomes));
        setRespdata(JSON.parse(incomes));
        setRetrieveStatus("");
        setHiddenNotification(true);
        return incomes;
    }

    useEffect(() => {
        // Оновлюємо заголовок документа, використовуючи API браузера
        //console.log(user);
        if (user.isAuthenticated) {
            //console.log(true)
            GetData();
            setRetrieveStatus("");
            setHiddenNotification(true);
        }
        else {
            //console.log(false);
            setRetrieveStatus(anonymousNotificationMessage);
            setHiddenNotification(false);
            setRespdata(testincome);
        }
        //GetData();

    }, [user]);

    //format date to the form 
    function formatDate(string){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    //when editing income add them to the temp state object
    function editIncome(income) {
        if (!ids.includes(income.ID)) {
            setIds(ids.concat(income.ID))
        }
        var obj = {
            ID: income.ID,
            Date: income.Date,
            Income: income.Income + '',
            Currency: income.Currency,
            Comment: income.Comment,
            Cash: income.Cash
        }
        var obj1 = {
            [income.ID]: {
                ID: income.ID,
                Date: income.Date,
                Income: income.Income  + '',
                Currency: income.Currency,
                Comment: income.Comment,
                Cash: income.Cash
            }
        }
        if (edits.length > 0) {
            edits.forEach(function (object) {
                if (object.ID === income.ID) {
                    //contains this object already
                }
                else {
                    setEdits(edits.concat(obj));
                }
            })
        }
        else {
            setEdits([].concat(obj));
        }

        if (Object.keys(edits1).length > 0) {
            Object.keys(edits1).forEach(function (key, object) {
                if (key === income.ID) {
                    //contains this object already
                }
                else {
                    
                    var tmpobj = edits1;
                    let newObj = Object.assign(tmpobj, obj1);
                    //console.log(tmpobj, newObj);
                    setEdits1(newObj);
                }
            })
        }
        else {
            setEdits1(obj1);
        }
        //setEdits(edits.concat(obj))
        console.log(ids, edits, edits1)
    }

    //after editing is done - remove the object (or income from object) from the state
    function deleteID(ID) {
        var inarr = ids;
        console.log(inarr)
        if (inarr.length > 1) {
            var index = inarr.indexOf(ID)
            inarr.splice(index, 1);
        }
        else {
            inarr = [];
        }
        setIds([].concat( inarr));
    }

    //after editing is done - remove the object (or income from object) from the state
    function deleteObj(ID) {
        var remobj = {};
        var inarr = edits;
        if (inarr.length > 1) {
            inarr.forEach(function (object) {
                if (object.ID === ID) {
                    remobj = object
                }
            })
            var index = inarr.indexOf(remobj);
            inarr.splice(index, 1);
        }
        else if (inarr.length === 1) {
            inarr.forEach(function (object) {
                if (object.ID === ID) {
                    inarr = [];
                }
            });
        }

        //
        Object.keys(edits1).forEach(function (key, value) {
            console.log(edits1)
            if (key === ID) {
                var newobj = edits1;
                //delete newobj[key];
                //var delstatusstatus = delete newobj[key];
                delete newobj[key];
                var tmpobj = Object.assign({},newobj);
                setEdits1(tmpobj);
                //setEdits1(newobj)
            }
        })
        //

        setEdits([].concat(inarr))
    }

    //send request to delete income from the server
    async function deleteIncome(income) {
        console.log('going to delete income ID: ' + income.ID);
        var tmpincome = income;
        tmpincome.Income = income.Income.toString();
        var resp = await fetchGet.fetchWithPost('/api/incomes/delete', income);
        alert(resp);
        incomes = await fetchGet.fetchWithGet("/api/incomes", null);
        setRespdata(JSON.parse(incomes));
    }

    //after editing merge edited stuff with the existing table
    function MergeEditWithTabledata( income) {
        var tabledata = respdata;
        Object.keys(respdata).forEach(function(key) {
            respdata[key].forEach(function(obj, index) {
                                    
                    if (obj.ID === income.ID) {
                        tabledata[key][index].Income = income.Income;
                        tabledata[key][index].Date = income.Date;
                        tabledata[key][index].Comment = income.Comment;
                        tabledata[key][index].Currency = income.Currency;
                        tabledata[key][index].Cash = income.Cash;
                        console.log('Found and changed income ID: ' + income.ID)
                    }  
            })
        })
        Object.assign({}, tabledata);
    }

    //merge new income with the table
    async function MergeNewWithTabledata (income) {
        var tabledata = respdata;
        var tmp = income.Date.split('-');
        var mnth;
        if (tmp[1] !== '10') {
            mnth =tmp[1].replace('0','');
        }
        else {
            mnth =tmp[1];
        }
        var group = tmp[0] + '-' + mnth;
        var outobj
        if (respdata[group]) {
            var inserted = false;
            //var tmparr = respdata[group]
            respdata[group].map(function(obj,index) {
                //console.log(Date.parse(obj.Date) , Date.parse(income.Date));
                if (Date.parse(income.Date) >= Date.parse(obj.Date)) {
                    tabledata[group].splice(index, 0, income)
                    inserted=true;
                }
                return inserted;
            })
            if (!inserted) {
                tabledata[group].push(income);
            }
            outobj = Object.assign({}, tabledata);
        }
        else {
            incomes = await fetchGet.fetchWithGet("/api/incomes", null);//.then(data => data.json());
            console.log(JSON.parse(incomes));
            outobj = JSON.parse(incomes)
            //setRespdata(JSON.parse(incomes));

        }
        
        var resp = Object.assign({},outobj)
        //console.log(resp);
        setRespdata(resp);
    }

    //send the edited income to the server to update it there
    async function applyChangedIncome(income) {
        alert('applying changes for income ' + income.Income + " : " + income.Comment);
        console.log(income);
        var resp = await fetchGet.fetchWithPost("/api/incomes/update", income);
        console.log (resp);
        if (resp != null)
        {
            MergeEditWithTabledata(income);
            deleteID(income.ID);
            deleteObj(income.ID);
        }
        
    }

    //cancel editing income
    function cancelChangedIncome(income) {
        alert('canceled changes for income ' + income.Income + " : " + income.Comment);
        deleteID(income.ID);
        deleteObj(income.ID);
    }


    function addNewIncome(e) {
        setAnchorEl(e.currentTarget);
        console.log(e);
    }

    function handleIncChange(e) {
        //console.log(e.target.value);
        var obj = edits1;
        var param = e.target.id.split('-')[0];
        var select = e.target.id.split('.');
        //console.log(select);
        //console.log(param)
        if(param === 'Cash'){
            obj[e.target.name][param] = e.target.checked;
        }
        else if(param === 'Date'){
            var date = new Date(e.target.value);
            var date2= date.toISOString();
            obj[e.target.name][param] = date2;
        }
        else if(select[0] === 'Currency') {
            obj[select[1]][select[0]] = e.target.value;
        }
        else {
            obj[e.target.name][param] = e.target.value;
        }
        var newobj = Object.assign({}, obj)
        setEdits1(newobj)
    }

    function handlePopuverClose(event) {
        setAnchorEl(null);
        setNewinc({
            ID: '',
            Date: new Date().toISOString().slice(0, 10),
            Income: '',
            Currency: 'UAH',
            Comment: '',
            Cash: false
        });
    }

    async function handleNewIncChange(e) {
        //console.log('handle new income')
        var obj = newinc;
        var param = e.target.id.split('-')[0];
        
        if(param === 'Cash'){
            obj[param] = e.target.checked;
        }
        else if(param === 'Date'){
            var date;
            try {
                date = new Date(e.target.value);
                var date2= date.toISOString().split('T')[0];
                obj[param] = date2;
            }
            catch {

            }
        }
        else if(param === 'Currency') {
            obj[param] = e.target.value;
        }
        else {
            obj[param] = e.target.value;
        }
        var newobj = Object.assign({}, obj)
        setNewinc(newobj)
    }

    async function addNew(event) {
        console.log('adding new...')
        var obj = newinc;
        // here I need to read ID of new inc and assign it to the obj before merging it with the table
        obj.ID='ID-'+ newinc.Income
        var resp = await fetchGet.fetchWithPost("/api/incomes/add", newinc);
        obj.ID = resp.split(': ')[1];

        await MergeNewWithTabledata(obj)
        handlePopuverClose(event);
    }

    return (
        
        <Suspense>

            {/*<Tooltip title="Додати новий дохід" arrow>*/}
            {/*</Tooltip>*/}
            <Notification isHidden={hiddenNotification} message={retrieveStatus} severity="warning" />
            <Tooltip title="Додати новий дохід" arrow>
                <IconButton variant="contained" color="primary" type="button" onClick={addNewIncome}>
                    <AddIcon />
                </IconButton> 
            </Tooltip>
            
            <Popover 
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopuverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                    <Table key='add-new-income' id='add-new-income'>
                        <TableBody  >
                            <TableRow key={'Row-new'} id={'Row-new'} >
                                <TableCell className={'table-date'} key={'Date-New'} id={'Date-New'} name={'Date-New'}>
                                    <TextField
                                        inputProps={{ style: { fontSize: 14 } }}
                                        type="date"
                                        key={'Date-New'}
                                        id={'Date-New'}
                                        name={'Date-New'}
                                        label='Дата'
                                        value={newinc.Date}
                                        className={'table-date-input'}
                                        onChange={(e) => handleNewIncChange(e)}
                                        InputLabelProps={{ shrink: true, }} >
                                    </TextField>
                                </TableCell>
                                <TableCell key={'Income-New'} className={'table-income'}>
                                    <TextField
                                        inputProps={{ style: { fontSize: 14 } }}
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        key={'Income-New'}
                                        id={'Income-New'}
                                        name={'Income-New'}
                                        label='Прибуток'
                                        value={newinc.Income}

                                        onChange={(e) => handleNewIncChange(e)}>

                                    </TextField>
                                </TableCell>
                                <TableCell key={'Currency-New'}>
                                    <FormControl className={classes.formControl} key={'Currency-FC-New'} name={'Currency-New'} value={newinc.Currency}>
                                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                                            Валюта
                                        </InputLabel>
                                        <NativeSelect
                                            key={'Currency-New'}
                                            name={'Currency-New'}
                                            defaultValue={'UAH'}
                                            label='Валюта'
                                            onChange={(e) => handleNewIncChange(e)}
                                            inputProps={{
                                                type: 'select',
                                                name: 'Currency-New',
                                                id: 'Currency-New',
                                                style: { fontSize: 14 }
                                            }} >
                                                
                                            <option value={'UAH'}>UAH</option>
                                            <option value={'USD'}>USD</option>
                                            <option value={'EUR'}>EUR</option>
                                            <option value={'PLN'}>PLN</option>
                                            <option value={'GBP'}>GBP</option>
                                        </NativeSelect>
                                    </FormControl>
                                </TableCell>
                                <TableCell key={'Comment-New'} className={'table-comment'}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        label='Коментар'
                                        key={'Comment-New'}
                                        id={'Comment-New'}
                                        inputProps={{ style: { fontSize: 14 } }}
                                        name={'Comment-New'}
                                        value={newinc.Comment}
                                        onChange={(e) => handleNewIncChange(e)}></TextField>
                                </TableCell>
                                <TableCell key={'Cash-New'} className={'table-cash'} align='center'>
                                    <Tooltip title="Готівка?">
                                        <Checkbox type="checkbox" key={'Cash-New'}
                                            id={'Cash-New'}
                                            
                                            size='small'
                                            name={'Cash-New'}
                                            checked={newinc.Cash}
                                            color="primary"
                                            onChange={(e) => handleNewIncChange(e)}
                                            inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                        </Checkbox>
                                    </Tooltip>
                                </TableCell>
                            <TableCell key={'btns-New'} className={'table-button'} align='center'>
                                <Tooltip title="Додати" arrow>
                                    <IconButton type="button" key={'BtnAdd-New'} id={'BtnAdd-New'} name={'BtnAdd-New'} variant="outline-success" onClick={(e) => addNew('')}><DoneIcon fontSize="small" /></IconButton>
                                </Tooltip>
                                <Tooltip title="Відмінити" arrow>
                                    <IconButton type="button" key={'BtnCancel-New'} id={'BtnCancel-New'} name={'BtnCancel-New'} variant="outline-warning" onClick={(e) => handlePopuverClose(e)}><CancelIcon fontSize="small" /></IconButton>
                                </Tooltip>
    
                                </TableCell>
                            </TableRow>
                        </TableBody  >                    
                    </Table>
                </Popover>
            {/*<Button variant="contained" color="primary" type="button" onClick={handleSubmit}>*/}
            {/*    Incomes*/}
            {/*</Button>*/}


            <div id = "test-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="simple table" key={"Main-Table-2"}>
                            <TableHead key={'main-header'}>
                                <TableRow key={'header-row'}>
                                    <TableCell >Дата</TableCell>
                                    <TableCell>Прибуток</TableCell>
                                    <TableCell >Валюта</TableCell>
                                    <TableCell>Коментар</TableCell>
                                    <TableCell className={'table-cash'} align='center'>Готівка?</TableCell>
                                    <TableCell className={'table-button'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'main-body-2'}>
                                {Object.keys(respdata).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'G-'+keyName} key={'G-2-'+keyName} name={'G-2'+keyName} colSpan="6" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respdata[keyName].map(function (value) {
                                                if ( Object.keys(edits1).includes(value.ID)) {
                                                    return ( 
                                                        <Fragment key={'f-Row' + value.ID}>
                                                            <TableRow key={'Row' + value.ID} id={value.ID} >
                                                                <TableCell className={'table-date'} key={'Date-' + value.ID} id={'Date-' + value.ID} name={'Date-' + value.ID}>
                                                                        <TextField
                                                                            inputProps={{style: {fontSize: 14}}}
                                                                            type="date"
                                                                            key={'Date-' + value.ID}
                                                                            id={'Date-' + value.ID} 
                                                                            name={value.ID} 
                                                                            value={edits1[value.ID].Date.split('T')[0]}
                                                                            className={'table-date-input'}
                                                                            onChange={(e) => handleIncChange(e)}
                                                                            InputLabelProps={{ shrink: true,}} >
                                                                        </TextField> 
                                                                </TableCell>
                                                                <TableCell key={'Income-' + value.ID} className={'table-income'}>
                                                                    <TextField 
                                                                        inputProps={{style: {fontSize: 14}}}
                                                                        key={'Income-' + value.ID} 
                                                                        id={'Income-' + value.ID} 
                                                                        name={value.ID} 
                                                                        value={edits1[value.ID].Income} 
                                                                        onChange={(e) => handleIncChange(e)}>

                                                                    </TextField>
                                                                </TableCell>
                                                                <TableCell key={'Currency-' + value.ID}>
                                                                    {/*<FormControl key={'Currency-' + value.ID} id={'Currency-' + value.ID} name={value.ID} value={edits1[value.ID].Currency} onChange={(e) => handleIncChange(e)}></FormControl>*/}
                                                                    <FormControl className={classes.formControl} key={'Currency-FC-' + value.ID} name={value.ID} value={edits1[value.ID].Currency}>
                                                                            <NativeSelect
                                                                                key={'Currency-' + value.ID}
                                                                                name={value.ID}
                                                                                defaultValue={edits1[value.ID].Currency}
                                                                                onChange={(e) => handleIncChange(e)}
                                                                                inputProps={{
                                                                                    type: 'select',
                                                                                    name: edits1[value.ID].Currency,
                                                                                    id: 'Currency.'+ value.ID,
                                                                                    style: {fontSize: 14}
                                                                                }} >
                                                                                <option value={'UAH'}>UAH</option>
                                                                                <option value={'USD'}>USD</option>
                                                                                <option value={'EUR'}>EUR</option>
                                                                                <option value={'PLN'}>PLN</option>
                                                                                <option value={'GBP'}>GBP</option>
                                                                            </NativeSelect>
                                                                    </FormControl>
                                                                </TableCell>
                                                                <TableCell key={'Comment-' + value.ID} className={'table-comment'}>
                                                                    <TextField 
                                                                        key={'Comment-' + value.ID} 
                                                                        id={'Comment-' + value.ID} 
                                                                        inputProps={{style: {fontSize: 14}}}
                                                                        name={value.ID} 
                                                                        value={edits1[value.ID].Comment} 
                                                                        onChange={(e) => handleIncChange(e)}></TextField>
                                                                </TableCell>
                                                                <TableCell key={'Cash-' + value.ID} className={'table-cash'} align='center'>
                                                                    <Checkbox type="checkbox" key={'Cash-' + value.ID} 
                                                                        id={'Cash-' + value.ID} 
                                                                        size='small'
                                                                        name={value.ID} 
                                                                        checked={edits1[value.ID].Cash} 
                                                                        color="primary"
                                                                        onChange={(e) => handleIncChange(e)}
                                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                                                    </Checkbox>
                                                                </TableCell>
                                                                <TableCell key={'btns-' + value.ID} className={'table-button'} align='center'>
                                                                    <Tooltip title="Зберегти зміни" arrow>
                                                                        <IconButton type="button" key={'BtnApply-' + value.ID} id={'BtnApply-' + value.ID} name={'BtnApply-' + value.ID} variant="outline-success" onClick={(e) => applyChangedIncome(edits1[value.ID])}><DoneIcon fontSize="small" /></IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Відмінити зміни" arrow>
                                                                        <IconButton type="button" key={'BtnCancel-' + value.ID} id={'BtnCancel-' + value.ID} name={'BtnCancel-' + value.ID} variant="outline-warning" onClick={(e) => cancelChangedIncome(value)}><CancelIcon fontSize="small" /></IconButton>
                                                                    </Tooltip>
                                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row' + value.ID}>
                                                            <TableRow key={value.ID} id={value.ID}>
                                                                <TableCell className={'table-date '} >
                                                                    {formatDate(value.Date)}
                                                                </TableCell>
                                                                <TableCell className={'table-income'}>
                                                                    {value.Income}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Currency}
                                                                </TableCell>
                                                                <TableCell className={'table-comment'}>
                                                                    {value.Comment}
                                                                </TableCell>
                                                                <TableCell className={'table-cash'} align='center'>
                                                                    <input type="checkbox" checked={value.Cash} disabled></input>
                                                                </TableCell>
                                                                <TableCell className={'table-button'} align='center'>
                                                                    <Tooltip title="Редагувати" arrow>
                                                                        <IconButton type="button" variant="outline-warning" onClick={(e) => editIncome(value)}><EditIcon fontSize="small" /></IconButton >
                                                                    </Tooltip>
                                                                    <Tooltip title="Видалити" arrow>
                                                                        <IconButton type="button" variant="outline-danger" onClick={(e) => deleteIncome(value)}><DeleteIcon fontSize="small" /></IconButton >
                                                                    </Tooltip>
                                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>

            {/*<MyTable.Table>
                <MyTable.Header>
                    <MyTable.Tdata>
                        FirstTitle
                    </MyTable.Tdata>
                    <MyTable.Tdata>
                        SecondTitle
                    </MyTable.Tdata>
                </MyTable.Header>
                <MyTable.Body>
                    <MyTable.Row>
                        <MyTable.Tdata>
                            DataFirst
                        </MyTable.Tdata>
                        <MyTable.Tdata>
                            DataSecond
                        </MyTable.Tdata>
                    </MyTable.Row>
                    <MyTable.Row>
                        <MyTable.Tdata>
                            <MyTable.Input key={1} value={'first'}/>
                        </MyTable.Tdata>
                        <MyTable.Tdata>
                            <FormControl key={2} value={email} onChange={(e) => setEmail(e.target.value) } />
                        </MyTable.Tdata>
                    </MyTable.Row>
                </MyTable.Body>
            </MyTable.Table> */}

        </Suspense>

        
    );
}