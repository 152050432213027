//const OtherComponent = React.lazy(() => import('./OtherComponent'));
import React, { useState, /*lazy,*/ Fragment, Suspense, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
import * as MyTable from "components/Incomes/table"
import ErrorBoundary from 'components/Helpers/Fuse'
import { CookiesAuth } from 'components/Helpers/CookieAuthHelper';
import Tooltip from '@material-ui/core/Tooltip';
import UserContext from 'components/Auth/UserContext';
import Notification from 'components/Shared/Notification';
const cookieAuth = new CookiesAuth();


const fetchGet = new FetchWithAuth();

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 500,
      fontSize: 14
    },
    tableButton: {
        maxWidth: 150,
      },
    tableDate: {
        minWidth: 200,
    },
    tableCurrency: {
        minWidth: 100,
    },
    typography: {
        padding: theme.spacing(2),
      },
  }));


export default function TaxPayed() {
    const anonymousNotificationMessage = "Для перегляду або вводу інформації потрібно авторизуватися";
    const [retrieveStatus, setRetrieveStatus] = useState(anonymousNotificationMessage);
    const [hiddenNotification, setHiddenNotification] = useState(false);
    const [respPayed, setRespPayed] = useState({});
    const classes = useStyles();
    const { user } = useContext(UserContext);

    var payedTax = {};
    
    useEffect(() => {
        // Оновлюємо заголовок документа, використовуючи API браузера
        //getData();
        if (user.isAuthenticated) {
            //console.log(true)
            getData();
        }
        else {
            setHiddenNotification(false);
            setRetrieveStatus(anonymousNotificationMessage);
            //console.log(false);
            //setRespdata(testincome);
        }
        
      }, [user]);

      async function getData() {
        if (cookieAuth.getAuthStatus()) {
            setRetrieveStatus("");
            setHiddenNotification(true);
            payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
            if (!payedTax)
            {
                payedTax = {};
                setRetrieveStatus(anonymousNotificationMessage);
                setHiddenNotification(false);
            }
            setRespPayed(JSON.parse(payedTax));
            console.log(JSON.parse(payedTax));
        }
        
        return payedTax;
    }

    // fetch expenses from the server
    //async function handleSubmit(event) {
    //    event.preventDefault();
    //    if (cookieAuth.getAuthStatus()) {
    //        setRetrieveStatus("");
    //        payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
    //        if (!payedTax)
    //        {
    //            payedTax = {};
    //            setRetrieveStatus("Для перегляду інформації потрібно авторизуватися");
    //        }
    //        setRespPayed(JSON.parse(payedTax));
    //        console.log(JSON.parse(payedTax));
    //    }
        
    //    return payedTax;
    //}

    //format date to the form 
    function formatDate(string){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    //send request to delete expense from the server
    async function deletePayedTax(tax) {
        console.log('going to delete tax ID: ' + tax.ID);
        //var tmptax = tax;
        //tmpexpense.Expense = expense.Expense.toString();
        var resp = await fetchGet.fetchWithPost('/api/taxes/remove', tax);
        alert(resp);
        getData();
        //setRespNotPayed(JSON.parse(notpayedTax));
    }


    return (
        
        <Suspense>

            <Notification isHidden={hiddenNotification} message={retrieveStatus} severity="warning" />

            <div>
                Сплачені податки
            </div>

            <div id = "payed-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="table with payed taxes" key={"payed-table"}>
                            <TableHead key={'payed-main-header'}>
                                <TableRow key={'payed-header-row'}>
                                    <TableCell>Місяць</TableCell>
                                    <TableCell>Прибуток</TableCell>
                                    <TableCell >Витрати</TableCell>
                                    <TableCell>ЄП</TableCell>
                                    <TableCell>ЄСВ</TableCell>
                                    <TableCell>ПДВ</TableCell>
                                    <TableCell className={'table-button-tax'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'payed-table-body'}>
                                {Object.keys(respPayed).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-payed-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-payed-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'q-payed-'+keyName} key={'q-payed-'+keyName} name={'q-payed'+keyName} colSpan="7" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respPayed[keyName].map(function (value) {
                                                if(false) {return null}//easier to modify like this
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row-payed' + value.ID}>
                                                            <TableRow key={'Row-payed-' + value.ID} id={value.ID}>
                                                                <TableCell >
                                                                    {formatDate(value.Date)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Incomes}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.Expenses}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.FlatTax}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.SSP}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.VAT}
                                                                </TableCell>
                                                                <TableCell className={'table-button-tax'} align='center'>
                                                                    {/*<IconButton  type="button" variant="outline-warning" onClick={(e) => editExpense(value)}><EditIcon fontSize="small"/></IconButton >*/}
                                                                    <Tooltip title="Видалити зі сплачених" arrow>
                                                                        <IconButton type="button" variant="outline-danger" onClick={(e) => deletePayedTax(value)}><DeleteIcon fontSize="small" /></IconButton >
                                                                    </Tooltip>
                                                                   
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>

            
        </Suspense>

        
    );
}