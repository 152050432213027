import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
//import { LoginMenu } from 'components/api-authorization/LoginMenu';
import UserContext from 'components/Auth/UserContext';
const cookies = new Cookies();


export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [authMessage, setAuthMessage] = useState("");
    const history = useHistory();
    const { user, setUser }  = useContext(UserContext);
    let state = { username: '', password: '' };
    console.log(user);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        setAuthMessage("");
        state.username = email;
        state.password = password;
        //alert('A form was submitted: ' + JSON.stringify(state));
        var resp = await fetch('/api/react/Authenticate/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "type": "formData"
            },
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(state)
        }).then(function (response,data) {
            console.log(response);
            console.log(response.status);
            console.log(data);
            if (response.status === 200)
            {
                return response.json();
            }
            
            return null;
        }).then((data) => {
            console.log(data);
            if (data != null)
            {
                
                //cookies.set('token', data.token, { path: '/', expires: new Date(data.expiration) });
                history.push('/incomes');
            }
            //loginMenu.componentDidMount();
            console.log(data)
            return data;
        });

        console.log(resp);
        if (resp) {
            //if (resp.token) {
                console.log("success");
                setUser({signedInUser: cookies.get('Session-User'), isAuthenticated: true});
                console.log(user);
            //}
        }
        else {
            alert("Помилка авторизації");
            setAuthMessage('Помилка авторизації. Неправильний логін чи пароль')
        }
        //loginMenu.populateState();
        //this.props.populateState();
    }


    return (
        <div className="Login">


            <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        autoFocus
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Button block size="lg" type="submit" disabled={!validateForm()}>
                    Увійти
                </Button>
                <Form.Group size="lg">
                    <Form.Label style={{color: 'red'}}>{authMessage}</Form.Label>
                    
                </Form.Group>
            </Form>
        </div>
    );
}