import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import UserContext from 'components/Auth/UserContext';
import './NavMenu.css';
//const loginMenu = new LoginMenu();
//let usr = {};

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = UserContext;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }

    externalUpdate() {
        this.props.updateState();
    }




    render() {
        //const { user, setUser } = this.context;
    return (
      <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
                    <NavbarBrand tag={Link} to="/">FOP.help(new)</NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Домашня</NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*  <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*  <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
                {/*</NavItem>*/}
                <LoginMenu>
                </LoginMenu>

              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

    // authenticatedView(userName) {
    //     return (<Fragment>
    //         <NavItem>
    //             <NavLink tag={Link} className="text-dark" to='/auth/ptofile'>Hello {userName}</NavLink>
    //         </NavItem>
    //         <NavItem>
    //             <NavLink tag={Link} className="text-dark" to='/auth/logout'>Logout</NavLink>
    //         </NavItem>
    //     </Fragment>);

    // }

    // anonymousView() {
    //     return (<Fragment>
    //         <NavItem>
    //             <NavLink tag={Link} className="text-dark" to='/auth/register'>Register</NavLink>
    //         </NavItem>
    //         <NavItem>
    //             <NavLink tag={Link} className="text-dark" to='/auth/login'>Login</NavLink>
    //         </NavItem>
    //     </Fragment>);
    // }
}


