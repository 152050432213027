//const OtherComponent = React.lazy(() => import('./OtherComponent'));
import React, { useState, /*lazy,*/ Fragment, Suspense, useEffect, useContext, useCallback, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
//import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
//import Button from '@material-ui/core/Button'
import * as MyTable from "components/Incomes/table"
import ErrorBoundary from 'components/Helpers/Fuse'
import { CookiesAuth } from 'components/Helpers/CookieAuthHelper';
import Tooltip from '@material-ui/core/Tooltip';
import UserContext from 'components/Auth/UserContext';
import Notification from 'components/Shared/Notification';

const cookieAuth = new CookiesAuth();


const fetchGet = new FetchWithAuth();

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 500,
      fontSize: 14
    },
    tableButton: {
        maxWidth: 150,
      },
    tableDate: {
        minWidth: 200,
    },
    tableCurrency: {
        minWidth: 100,
    },
    typography: {
        padding: theme.spacing(2),
      },
  }));


export default function TaxPending() {
    const [respNotPayed, setRespNotPayed] = useState({});
    const anonymousNotificationMessage = "Для перегляду або вводу інформації потрібно авторизуватися";
    const [retrieveStatus, setRetrieveStatus] = useState(anonymousNotificationMessage);
    const [hiddenNotification, setHiddenNotification] = useState(false);
    const classes = useStyles();
    const { user } = useContext(UserContext);

    
    var notpayedTax = {};
    const notpayedTaxRef = useRef({});

    const GetData = useCallback(async () => {
        if (cookieAuth.getAuthStatus()) {
            setRetrieveStatus("");
            setHiddenNotification(true);
            notpayedTaxRef.current = await fetchGet.fetchWithGet("/api/taxes", null);
            if (!notpayedTaxRef.current) {
                notpayedTaxRef.current = {};
                setRetrieveStatus("Для перегляду інформації потрібно авторизуватися");
                setHiddenNotification(false);
            }
            console.log(JSON.parse(notpayedTaxRef.current));
            setRespNotPayed(JSON.parse(notpayedTaxRef.current));
            console.log("retireved taxes from useCallback")
        }
        //return notpayedTax;
    }, [notpayedTaxRef]);

    useEffect(() => {
        //getData();
        if (user.isAuthenticated) {
            //console.log(true)
            GetData();
        }
        else {
            setHiddenNotification(false);
            setRetrieveStatus(anonymousNotificationMessage);
            //console.log(false);
            //setRespdata(testincome);
        }
        
    }, [user, GetData]);

    async function getData() {
        if (cookieAuth.getAuthStatus()) {
            setRetrieveStatus("");
            setHiddenNotification(true);
            notpayedTax = await fetchGet.fetchWithGet("/api/taxes", null);
            if (!notpayedTax)
            {
                notpayedTax = {};
                setRetrieveStatus(anonymousNotificationMessage);
                setHiddenNotification(false);
            }
            console.log(JSON.parse(notpayedTax));
            setRespNotPayed(JSON.parse(notpayedTax));
        }
        return notpayedTax;
    }

    // fetch expenses from the server
    //async function handleSubmit(event) {
    //    event.preventDefault();
    //    setRetrieveStatus("");
    //    if (cookieAuth.getAuthStatus()) {
    //        notpayedTax = await fetchGet.fetchWithGet("/api/taxes", null);
    //        if (!notpayedTax)
    //        {
    //            notpayedTax = {};
    //            setRetrieveStatus("Для перегляду інформації потрібно авторизуватися");
    //        }
    //        console.log(JSON.parse(notpayedTax));
    //        setRespNotPayed(JSON.parse(notpayedTax));
    //        // payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
    //        // setRespPayed(JSON.parse(payedTax));
    //        // console.log(JSON.parse(notpayedTax), JSON.parse(payedTax));
    //    }
        
    //    return notpayedTax;
    //}

    //format date to the form 
    function formatDate(string){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    //send request to delete expense from the server
    async function PayTax(tax) {
        console.log('going to pay tax ID: ' + tax.ID);
        //var tmptax = tax;
        //tmpexpense.Expense = expense.Expense.toString();
        var resp = await fetchGet.fetchWithPost('/api/taxes/pay', tax);
        alert(resp);
        //payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
        getData();
        //setRespPayed(JSON.parse(payedTax));
        //setRespNotPayed(JSON.parse(notpayedTax));
    }


    return (
        
        <Suspense>
            {/*<Button variant="contained" color="primary" type="button" onClick={handleSubmit}>*/}
            {/*    Expenses*/}
            {/*</Button>*/}
            {/*{notification(retrieveStatus)}*/}
            <Notification isHidden={hiddenNotification} message={ retrieveStatus} severity="warning"/>
            <div>
                Не сплачені податки
            </div>

            <div id = "not-payed-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="simple table" key={"not-payed-table"}>
                            <TableHead key={'main-header'}>
                                <TableRow key={'header-row'}>
                                    <TableCell>Місяць</TableCell>
                                    <TableCell>Прибуток</TableCell>
                                    <TableCell >Витрати</TableCell>
                                    <TableCell>ЄП</TableCell>
                                    <TableCell>ЄСВ</TableCell>
                                    <TableCell>ПДВ</TableCell>
                                    <TableCell className={'table-button-tax'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'not-payed-table-body'}>
                                {Object.keys(respNotPayed).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-notpayed-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-notpayed-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'q-notpayed-'+keyName} key={'q-notpayed-'+keyName} name={'q-notpayed'+keyName} colSpan="7" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respNotPayed[keyName].map(function (value) {
                                                if(false) { return null}//easier to modify like this
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row-notpayed' + value.ID}>
                                                            <TableRow key={'Row-notpayed-' + value.ID} id={value.ID}>
                                                                <TableCell >
                                                                    {formatDate(value.Date)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Incomes}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.Expenses}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.FlatTax}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.SSP}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.VAT}
                                                                </TableCell>
                                                                <TableCell className={'table-button-tax'} align='center'>
                                                                    <Tooltip title="Відмітити про сплату" arrow>
                                                                        <IconButton type="button" variant="outline-warning" onClick={(e) => PayTax(value)}><AssignmentTurnedInOutlinedIcon fontSize="small" /></IconButton >
                                                                    </Tooltip>
                                                                    
                                                                    {/*<IconButton  type="button" variant="outline-danger" onClick={(e) => deleteExpense(value)}><DeleteIcon fontSize="small"/></IconButton >*/}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>
            
            

            
        </Suspense>

        
    );
}