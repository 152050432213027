import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import Login from './components/Auth/Login';
import Confirm from './components/Auth/Confirm';
import Regcomplete from './components/Auth/Regcomplete';
import { Register } from './components/Auth/Register';
import { Recover } from './components/Auth/Recover';
import Incomes from './components/Incomes/Incomes';
import Expenses from './components/Expenses/Expenses';
import Taxes from './components/Taxes/Taxes';
import TaxPending from './components/Taxes/TaxPending';
import TaxPayed from './components/Taxes/TaxPayed';
import {Reports} from './components/Reports/Reports';
import ReportsAll from './components/Reports/ReportsAll';
import ReportsDone from './components/Reports/ReportsDone';
import { DetailedReport } from 'components/Reports/DetailedReport';
import { GovSums } from './components/GovSums/GovSums';
import {Logout} from 'components/Auth/Logout';
import './custom.css';
import UserContext from 'components/Auth/UserContext';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
let user =  {signedInUser: "fun guest", isAuthenticated: false};




//import './components/css/site.css'

export default class App extends Component {
  static displayName = App.name;
  state = {
    user: {},
  } 
  componentDidMount() {
    console.log('App mount');
    if (cookies.get('Session-User')) {
      //console.log(cookies.get('Session-User'));
    this.setUser ({signedInUser: cookies.get('Session-User'), isAuthenticated: true});
    }
    // else {
    //   user =  {signedInUser: null, isAuthenticated: false};
    // }
  }

  setUser = (user) => {
      this.setState((prevState) => ({ user }))
      console.log(this.state.user);
  }

  static changeState(usrName, isAuth) {
    user = {signedInUser: usrName, isAuthenticated: isAuth}
    console.log(usrName, isAuth);
    console.log(user)
    return user;
    
  }

  render () {
    //const { children } = this.props
    const { user } = this.state
    const { setUser } = this
    //const user =  {signedInUser: "fun guest", isAuthenticated: false};
    //console.log(signedInUser, isAuthenticated);
    return (
      <UserContext.Provider value={{user,setUser}}>
        <Layout>
          <Route exact path='/' component={Home} />
          <Route path='/counter' component={Counter} />
          <Route exact path='/auth/login' component={Login} />
          <Route exact path='/auth/register' component={Register} />
              <Route exact path='/auth/recover' component={Recover} />
              <Route exact path='/auth/logout' component={Logout} />
              <Route exact path='/auth/confirm' component={Confirm} />
              <Route exact path='/auth/regcomplete' component={Regcomplete} />
          <Route exact path='/incomes' component={Incomes} />
          <Route exact path='/expenses' component={Expenses} />
          <Route exact path='/taxes' component={Taxes} />
          <Route exact path='/taxes/pending' component={TaxPending} />
          <Route exact path='/taxes/payed' component={TaxPayed} />
          <Route exact path='/reports' component={Reports} />
          <Route exact path='/reports/all' component={ReportsAll} />
                <Route exact path='/reports/done' component={ReportsDone} />
                <Route exact path='/reports/details' component={DetailedReport} />
          <Route exact path='/GovSums' component={GovSums} />
          <AuthorizeRoute path='/fetch-data' component={FetchData} />
          <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        </Layout>
      </UserContext.Provider>
    );
  }
}
