import React, { Component, Fragment } from 'react';
//import { Route } from 'react-router';
//import { Login } from './Login'
//import { Logout } from './Logout'
//import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';

export default class ApiAuthorizationRoutes extends Component {

  render () {
    return(
      <Fragment>


      </Fragment>);
  }
}

//function loginAction(name){
//    return (<Login action={name}></Login>);
//}

//function logoutAction(name) {
//    return (<Logout action={name}></Logout>);
//}
