import React from 'react';
import {Navigation} from 'react-minimal-side-navigation';
import Icon from "awesome-react-icons";
//import styles from "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";

import { useHistory} from "react-router-dom";
//let history = useHistory();


export function SideMenu(){
    //static displayName = SideMenu.name;
    const history = useHistory();
    //const location = useLocation();
    //const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    

  //render () {
    return (
      <div className="use-menu-lite">
        <Navigation
            // you can use your own router's api to get pathname
            activeItemId="/management/members"
            onSelect={({itemId}) => {
              // maybe push to the route
              history.push(itemId);
            }}
            items={[
              {
                title: 'Прибутки',
                itemId: '/incomes',
                // you can use your own custom Icon component as well
                // icon is optional
                elemBefore: () => <Icon name="arrow-right" />,
              },
              {
                title: 'Витрати',
                itemId: '/expenses',
                elemBefore: () => <Icon name="arrow-left" />,
                // subNav: [
                //   {
                //     title: 'Projects',
                //     itemId: '/expenses/projects',
                //   },
                //   {
                //     title: 'Members',
                //     itemId: '/expenses/members',
                //   },
                // ],
              },
              {
                title: 'Податки',
                itemId: '/taxes',
                // you can use your own custom Icon component as well
                // icon is optional
                elemBefore: () => <Icon name="briefcase" />,
                 subNav: [
                   {
                     title: 'Поточні',
                     itemId: '/taxes/pending',
                   },
                   {
                     title: 'Сплачені',
                     itemId: '/taxes/payed',
                   },
                 ],
              },
              {
                title: 'Звіти',
                itemId: '/reports',
                // you can use your own custom Icon component as well
                // icon is optional
                elemBefore: () => <Icon name="book" />,
                subNav: [
                  {
                    title: 'Усі',
                    itemId: '/reports/all',
                  },
                  {
                    title: 'Подані',
                    itemId: '/reports/done',
                  },
                ],
              },
              {
                title: 'Основи для розрахунку',
                itemId: '/GovSums',
                elemBefore: () => <Icon name="external-link" />,
                // subNav: [
                //   {
                //     title: 'Teams',
                //     itemId: '/management/teams',
                //   },
                // ],
              },
            ]}
          />
      </div>
    );
  //}
}
