//const OtherComponent = React.lazy(() => import('./OtherComponent'));
import React, { useState, lazy, Fragment, Suspense  } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
import Button from '@material-ui/core/Button'
import * as MyTable from "components/Incomes/table"
import ErrorBoundary from 'components/Helpers/Fuse'
import { CookiesAuth }  from 'components/Helpers/CookieAuthHelper';
const cookieAuth = new CookiesAuth();


const fetchGet = new FetchWithAuth();

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 500,
      fontSize: 14
    },
    tableButton: {
        maxWidth: 150,
      },
    tableDate: {
        minWidth: 200,
    },
    tableCurrency: {
        minWidth: 100,
    },
    typography: {
        padding: theme.spacing(2),
      },
  }));


export default function Taxes() {
    const [respNotPayed, setRespNotPayed] = useState({});
    const [respPayed, setRespPayed] = useState({});
    const classes = useStyles();

    var payedTax = {};
    var notpayedTax = {};

    // fetch expenses from the server
    async function handleSubmit(event) {
        event.preventDefault();
        if (cookieAuth.getAuthStatus()) {
            notpayedTax = await fetchGet.fetchWithGet("/api/taxes", null);
            console.log(JSON.parse(notpayedTax));
            setRespNotPayed(JSON.parse(notpayedTax));
            payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
            setRespPayed(JSON.parse(payedTax));
            console.log(JSON.parse(notpayedTax), JSON.parse(payedTax));
        }
        
        return notpayedTax, payedTax;
    }

    //format date to the form 
    function formatDate(string){
        var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(string).toLocaleDateString([],options);
    }

    //send request to delete expense from the server
    async function deletePayedTax(tax) {
        console.log('going to delete expense ID: ' + tax.ID);
        //var tmptax = tax;
        //tmpexpense.Expense = expense.Expense.toString();
        var resp = await fetchGet.fetchWithPost('/api/expenses/delete', tax);
        alert(resp);
        payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
        notpayedTax = await fetchGet.fetchWithGet("/api/taxes", null);
        setRespPayed(JSON.parse(payedTax));
        setRespNotPayed(JSON.parse(notpayedTax));
    }

    async function PayTax(tax) {
        console.log('going to pay tax ID: ' + tax.ID);
        //var tmptax = tax;
        //tmpexpense.Expense = expense.Expense.toString();
        var resp = await fetchGet.fetchWithPost('/api/taxes/pay', tax);
        alert(resp);
        //payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
        getData();
        //setRespPayed(JSON.parse(payedTax));
        //setRespNotPayed(JSON.parse(notpayedTax));
    } 

    async function getData() {
        // if (cookieAuth.getAuthStatus()) {
        //     setRetrieveStatus("");
        //     payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
        //     if (!payedTax)
        //     {
        //         payedTax = {};
        //         setRetrieveStatus("Для перегляду інформації потрібно авторизуватися");
        //     }
        //     setRespPayed(JSON.parse(payedTax));
        //     console.log(JSON.parse(payedTax));
        // }
        
        // return payedTax;
    }


    return (
        
        <Suspense>
            <Button variant="contained" color="primary" type="button" onClick={handleSubmit}>
                Expenses
            </Button>

            <div>
                Не сплачені податки
            </div>

            <div id = "not-payed-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="simple table" key={"not-payed-table"}>
                            <TableHead key={'main-header'}>
                                <TableRow key={'header-row'}>
                                    <TableCell>Місяць</TableCell>
                                    <TableCell>Прибуток</TableCell>
                                    <TableCell >Витрати</TableCell>
                                    <TableCell>ЄП</TableCell>
                                    <TableCell>ЄСВ</TableCell>
                                    <TableCell>ПДВ</TableCell>
                                    <TableCell className={'table-button-tax'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'not-payed-table-body'}>
                                {Object.keys(respNotPayed).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-notpayed-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-notpayed-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'q-notpayed-'+keyName} key={'q-notpayed-'+keyName} name={'q-notpayed'+keyName} colSpan="7" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respNotPayed[keyName].map(function (value) {
                                                if(false) {}//easier to modify like this
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row-notpayed' + value.ID}>
                                                            <TableRow key={'Row-notpayed-' + value.ID} id={value.ID}>
                                                                <TableCell className={'table-date '} >
                                                                    {formatDate(value.Date)}
                                                                </TableCell>
                                                                <TableCell className={'table-income'}>
                                                                    {value.Incomes}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Expenses}
                                                                </TableCell>
                                                                <TableCell className={'table-comment'}>
                                                                    {value.FlatTax}
                                                                </TableCell>
                                                                <TableCell className={'table-cash'} align='center'>
                                                                    {value.SSP}
                                                                </TableCell>
                                                                <TableCell className={'table-cash'} align='center'>
                                                                    {value.VAT}
                                                                </TableCell>
                                                                <TableCell className={'table-button'} align='center'>
                                                                    <IconButton  type="button" variant="outline-warning" onClick={(e) => PayTax(value)}><AssignmentTurnedInOutlinedIcon fontSize="small"/></IconButton >
                                                                    {/*<IconButton  type="button" variant="outline-danger" onClick={(e) => deleteExpense(value)}><DeleteIcon fontSize="small"/></IconButton >*/}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>
            
            <div>
                <br />
                Сплачені податки
            </div>

            <div id = "payed-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="table with payed taxes" key={"payed-table"}>
                            <TableHead key={'payed-main-header'}>
                                <TableRow key={'payed-header-row'}>
                                    <TableCell>Місяць</TableCell>
                                    <TableCell>Прибуток</TableCell>
                                    <TableCell >Витрати</TableCell>
                                    <TableCell>ЄП</TableCell>
                                    <TableCell>ЄСВ</TableCell>
                                    <TableCell>ПДВ</TableCell>
                                    <TableCell className={'table-button-tax'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'payed-table-body'}>
                                {Object.keys(respPayed).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-payed-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-payed-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'q-payed-'+keyName} key={'q-payed-'+keyName} name={'q-payed'+keyName} colSpan="7" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respPayed[keyName].map(function (value) {
                                                if(false) {}//easier to modify like this
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row-payed' + value.ID}>
                                                            <TableRow key={'Row-payed-' + value.ID} id={value.ID}>
                                                                <TableCell className={'table-date '} >
                                                                    {formatDate(value.Date)}
                                                                </TableCell>
                                                                <TableCell className={'table-income'}>
                                                                    {value.Incomes}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Expenses}
                                                                </TableCell>
                                                                <TableCell className={'table-comment'}>
                                                                    {value.FlatTax}
                                                                </TableCell>
                                                                <TableCell className={'table-cash'} align='center'>
                                                                    {value.SSP}
                                                                </TableCell>
                                                                <TableCell className={'table-cash'} align='center'>
                                                                    {value.VAT}
                                                                </TableCell>
                                                                <TableCell className={'table-button'} align='center'>
                                                                    {/*<IconButton  type="button" variant="outline-warning" onClick={(e) => editExpense(value)}><EditIcon fontSize="small"/></IconButton >*/}
                                                                    <IconButton  type="button" variant="outline-danger" onClick={(e) => deletePayedTax(value)}><DeleteIcon fontSize="small"/></IconButton >
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>

            
        </Suspense>

        
    );
}