import React, { Component} from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
//import Col from "react-bootstrap/Col";
//import { useHistory } from "react-router-dom";

export class Register extends Component {
    constructor(props) {
        super(props);
        //this.handleLoginClick = this.handleLoginClick.bind(this);
        //this.handleLogoutClick = this.handleLogoutClick.bind(this);
        this.state = { isLoggedIn: false };
        this.state = {
            email: "",
            password: "",
            confpassword: "",
            fopvat: false,
            fopgeneral: false,
            fopgroup: 0,
            registesubmitted: false,
            regmessage: "",
            valerrors: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        //this.render = this.render.bind(this);
    }
    static displayName = Register.name;
    //const[email, setEmail] = useState("");
    //const[password, setPassword] = useState("");
    //const history = useHistory();

    //validateForm() {
    //    return this.state.email > 0 && this.state.password > 0 && this.state.confpassword > 0;
    //}

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.validateAllForm()) {
            console.log("error during validation of the input");
        }
        else {
            let regmod = {};
            //let history = useHistory();
            regmod.Email = this.state.email;
            regmod.Password = this.state.password;
            regmod.FopVat = this.state.fopvat;
            regmod.FopGeneral = this.state.fopgeneral;
            regmod.FopGroup = parseInt(this.state.fopgroup);
            console.log(JSON.stringify(regmod));
            fetch('/api/react/Authenticate/register', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "type": "formData"
                },
                // We convert the React state to JSON and send it as the POST body
                body: JSON.stringify(regmod)
            }).then( response => {
                //console.log(response);
                console.log(response.status);
                //console.log(data);
                if (response.status === 200) {
                    return response.json();
                    //this.setState({ regmessage: "Користувача успішно створено. На вказану електронну адресу висланий лист з посиланням для підтвердження реєстрації" });
                }
                if (response.status === 500) {
                    return response.json();
                }


                return null;
            }).then(data => {
                //if (data != null) {
                //    console.log(data);
                //}
                if (data != null) {
                    this.setState({ registesubmitted: true })
                    //this.setState({ regmessage: "Користувача успішно створено. На вказану електронну адресу висланий лист з посиланням для підтвердження реєстрації" })
                    data = JSON.parse(data);
                    console.log(data);
                    if (data.Status === "Success") {
                        this.setState({ regmessage: "Користувач успішно зареєстрований. На вашу електронну адресу надіслано лист з підтвердженням реєстрації" })
                    }
                    else if (data.Status === "Error") {
                        this.setState({ regmessage: "Помилка реєстрації на сервері.Перевірте дані та спробуйте ще раз" });
                        console.log(data.Status);
                        console.log(this.state.regmessage + " in response")
                    }
                }

            });
        }
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0 && this.state.confpassword.length > 0;
    }

    validateAllForm() {
        var valid = true;
        var arr = [];
        this.setState({ valerrors: arr });
        if (this.state.password.length < 6) {
            arr.push("Парроль повинен містити не менше 6 символів");
            valid = false;
        }
        if (this.state.password !== this.state.confpassword) {
            arr.push("Паролі не збігаються");
            valid = false;
        }
        if (this.state.fopgroup < 1 && this.state.fopgroup > 4) {
            arr.push("Група фоп повинна бути від 1 до 4");
            valid = false;
        }
        if (arr.length > 0) {
            this.setState({valerrors: arr});
        }
        return valid;
    }

    

    render() {
        //let regform;
        if (!this.state.registesubmitted) {
            
        }
        else {
           
        }
        let Errlist = this.state.valerrors.map((error, index) =>
            <li key={"error-"+index}>{error}</li>
        );

        if (!this.state.registesubmitted) {
            return (
                <div className="Login">
                    <ul style={{ color: 'red' }}>{Errlist}</ul>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group size="lg" controlId="formEmail">
                            <Form.Label>Електонна пошта</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="введіть email"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })} />
                            <Form.Text className="text-muted">
                                Ми не розголошуємо ваш е-мейл нікому
                          </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formPassword">
                            <Form.Label>Пароль</Form.Label>
                            <Form.Control type="password"
                                placeholder="Пароль"
                                value={this.state.password}
                                onChange={(e) => this.setState({ password: e.target.value })} />
                        </Form.Group>
                        <Form.Group controlId="formConfirmPassword">
                            <Form.Label>Підтвердження паролю</Form.Label>
                            <Form.Control type="password"
                                placeholder="Повторіть пароль"
                                value={this.state.confpassword}
                                onChange={(e) => this.setState({ confpassword: e.target.value })} />
                        </Form.Group>
                        <Form.Group controlId="formFopVat">
                            <Form.Check type="checkbox" label="ФОП є платником ПДВ"
                                checked={this.state.fopvat}
                                onChange={(e) => this.setState({ fopvat: e.target.checked })} />
                        </Form.Group>
                        <Form.Group controlId="formFopGeneral">
                            <Form.Check type="checkbox" label="ФОП на загальній системі оподаткування"
                                checked={this.state.fopgeneral}
                                onChange={(e) => this.setState({ fopgeneral: e.target.checked })} />
                        </Form.Group>
                        <Form.Group controlId="formFopGroup">
                            <Form.Label>Група ФОП</Form.Label>
                            <Form.Control as="select"
                                value={this.state.fopgroup}
                                onChange={(e) => this.setState({ fopgroup: e.target.value })}>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </Form.Control>
                        </Form.Group>
                        <Button block type="submit" disabled={!this.validateForm()}>
                            Зареєструватися
                      </Button>
                    </Form>
                    <br />
                    <Form>
                        <Form.Row className="align-items-center">
                            <Button block variant="link" type="submit" onClick={ () => this.props.history.push("/auth/login")}>
                                Є акаунт? Увійти
                          </Button>
                        </Form.Row>
                    </Form>
                </div>
            );
        }
        else {
            console.log(this.state);
            return (
                <h5>
                    { this.state.regmessage}
                </h5>
            );
        }
       
  }
}
