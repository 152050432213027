import React, { Component } from 'react';
//import { LoginMenu } from 'components/api-authorization/LoginMenu';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
import UserContext from 'components/Auth/UserContext'
//import { useContext } from 'react';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
//import App from 'App'
const fetchHelper = new FetchWithAuth();
//const loginMenu = new LoginMenu();
//var app = new App();
//const user = null;



export  class Logout extends Component {
    
    static displayName = Logout.name;
    static contextType = UserContext;
    //const history = useHistory();

    
    //static user = this.context;
    constructor(props) {
        super(props);
        this.state = { isLoggedIn: false };
        this.state = {
            response: '',
            usr: {}
        }
        
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.logout = this.logout.bind(this);
    }
    
    componentDidMount() {
        const {user} = this.context;
        //this.logout();
        console.log('did mount');
        console.log(user);
        this.logout();
        //const usr = {signedInUser: "changed", isAuthenticated: true}
        //setUser(usr);
        //console.log(user);
        
    }

    async logout() {
        const {user, setUser} = this.context;
        //var usr = useContext(UserContext);
        console.log(this.props);
        var logoutmessage = await fetchHelper.fetchWithGetNoAuth('/api/react/Authenticate/logout', null);
        console.log(logoutmessage);
        //loginMenu.setState({isAuthenticated: false, userName: null});
        //loginMenu.updateFromExternal();
        if (!logoutmessage)
        {
            logoutmessage = "error"
        }
        this.setState({ response: logoutmessage });
        let usrr = {signedInUser: 'Logged Out', isAuthenticated: false};
        setUser(usrr)
        this.setState({user: user})
        console.log(this.state.user)
        
    }

  render () {
      const { user } = this.context;
      if (!!user && !user.isAuthenticated) {
          return (
              <div className="Login" style={{ textAlign: "center" }}>
                  <h3>Користувач успішно покинув систему</h3>
                  <Form >
                      <Button block size="lg" type="button" onClick={() => this.props.history.push("/auth/login")}>
                          Увійти знову
                      </Button>
                  </Form>
              </div>
          );
      }
      else {
          return (
              <div className="Login" style={{ textAlign: "center" }}>
                  <h3>Щось поішло не так під час виходу користувача...</h3>
                  <Form >
                      <Button block size="lg" type="button" onClick={this.logout}>
                          Спробувати знову
                      </Button>
                  </Form>
              </div>
          );
      }
    
  }
}
