import React, { Component } from 'react';

export class FetchWithAuth extends Component {
    static displayName = FetchWithAuth.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            responseData: JSON.stringify(null)
        };
        this.fetchWithPost = this.fetchWithPost.bind(this);
        this.incrementCounter = this.incrementCounter.bind(this);
        this.fetchWithGet = this.fetchWithGet.bind(this);
    }
    //SAFE_componentWillMount() {
    //    this.fetchWithGet = this.fetchWithGet.bind(this);
    //}

   


    incrementCounter() {
        this.setState({
             currentCount: this.state.currentCount + 1
        });
    }

    async fetchWithGet(sUrl, objParams) {
        var params = '';
        if (objParams !== null || objParams !== undefined) {
            params = '?';
            var i = 0;
            for (var x in objParams) {
                var tmpParam = x + "=" + objParams[x];
                if (i < Object.keys(objParams).length - 1) {
                    tmpParam = tmpParam + '&';
                }
                params = params + tmpParam;
                i = i + 1;
            }
        }
        //refresh token first
        var refreshToken = await fetch('/api/react/Authenticate/refresh', {
            method: 'GET',
        }).then(function (response) {
            return response.status;
        });
        
        if (refreshToken !== 200) {
            return  null;
            
        }
        console.log("refresh token OK");
        var fetchdata =  await fetch(sUrl + params, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                //"type": "formData"
            },
            // We convert the React state to JSON and send it as the POST body
            //body: JSON.stringify(state)
        }).then(function (response) {
            console.log("then function: => " + response);
            console.log(response.status);

            if (response.status === 200) {
                return response.json();
            }

            return null;
        }).then((data) => {
            if (data != null) {
                
            }
            //console.log("FetchGet then => " + JSON.stringify(data));
            return data;
        });
        //console.log( await fetchdata);
        return await fetchdata;
    }

    async fetchWithPost(sUrl, objParams) {
        //refresh token first
        var refreshToken = await fetch('/api/react/Authenticate/refresh', {
            method: 'GET',
        }).then(function (response) {
            return response.status;
        });

        if (refreshToken !== 200) {
            console.log("refresh token NOK");
            return null;
        }
        console.log("refresh token OK");

        var fetchdata = await fetch(sUrl, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                //"type": "formData"
            },
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(objParams)
        }).then(function (response) {
            //console.log("then function: => " + response);
            console.log(response.status);

            if (response.status === 200) {
                return response.json();
            }

            return null;
        }).then((data) => {
            if (data != null) {

            }
            return data;
        });
        console.log(await fetchdata);
        return await fetchdata;
    }

    async fetchWithPostStatus(sUrl, objParams) {
        //refresh token first
        var refreshToken = await fetch('/api/react/Authenticate/refresh', {
            method: 'GET',
        }).then(function (response) {
            return response.status;
        });

        if (refreshToken !== 200) {
            console.log("refresh token NOK");
            return null;
        }
        console.log("refresh token OK");

        var fetchdata = await fetch(sUrl, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                //"type": "formData"
            },
            // We convert the React state to JSON and send it as the POST body
            body: JSON.stringify(objParams)
        }).then(function (response) {
            //console.log("then function: => " + response);
            console.log(response.status);

            if (response.status === 200) {
                return response.json();
            }

            return null;
        }).then((data) => {
            if (data != null) {

            }
            return data;
        });
        console.log(await fetchdata);
        return await fetchdata;
    }

    async fetchWithGetNoAuth(sUrl, objParams) {
        var params = '';
        if (objParams !== null || objParams !== undefined) {
            params = '?';
            var i = 0;
            for (var x in objParams) {
                var tmpParam = x + "=" + objParams[x];
                if (i < Object.keys(objParams).length - 1) {
                    tmpParam = tmpParam + '&';
                }
                params = params + tmpParam;
                i = i + 1;
            }
        }
        //refresh token first
        // var refreshToken = await fetch('/api/react/Authenticate/refresh', {
        //     method: 'GET',
        // }).then(function (response) {
        //     return response.status;
        // });
        
        // if (refreshToken !== 200) {
        //     return  null;
            
        // }
        // console.log("refresh token OK");
        var fetchdata =  await fetch(sUrl + params, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                //"type": "formData"
            },
            // We convert the React state to JSON and send it as the POST body
            //body: JSON.stringify(state)
        }).then(function (response) {
            console.log("then function: => " + response);
            console.log(response.status);

            if (response.status === 200) {
                return response.json();
            }

            return null;
        }).then((data) => {
            if (data != null) {
                
            }
            //console.log("FetchGet then => " + JSON.stringify(data));
            return data;
        });
        //console.log( await fetchdata);
        return await fetchdata;
    }

  render() {
    return (
      <div>
        <h1>Counter</h1>

        <p>This is a simple example of a React component.</p>

        <p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>

        <button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
      </div>
    );
  }
}
