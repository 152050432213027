import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
//import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { CookiesAuth }  from 'components/Helpers/CookieAuthHelper';
import UserContext from 'components/Auth/UserContext';
const cookieAuth = new CookiesAuth();

export class LoginMenu extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            usr: {}
        };
        this.render = this.render.bind(this)
        this.populateState = this.populateState.bind(this)
        this.updateFromExternal = this.updateFromExternal.bind(this)
    }

    componentDidMount() {
        //this._subscription = authService.subscribe(() => this.populateState());
        //const {user, setUser} = this.context;
        this.populateState();
    }

    componentWillUnmount() {
        //authService.unsubscribe(this._subscription);
    }

    //componentDidUpdate() {
    //    this.populateState();
    //}

    updateFromExternal() {
        this.populateState();
    }


    async populateState() {
        //console.log(cookieAuth.getCookieUser());
        if (cookieAuth.getCookieUser()) {
            const user = cookieAuth.getCookieUser();//await Promise.all([authService.isAuthenticated(), authService.getUser()])
            this.setState({
                isAuthenticated: true,
                userName: user
            });
            console.log(this.state.userName)
        }
        else {
            this.setState({
                isAuthenticated: false,
                userName: null
            });
        }
       
    }

    render() {
        //const { isAuthenticated, userName } = {user.isAuthenticated, user.};
        const {user} = this.context;
        if (!user.isAuthenticated) {
            
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            console.log(user.signedInUser);
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(user.signedInUser, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Вітаю, {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Вийти</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Реєстрація</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Увійти</NavLink>
            </NavItem>
        </Fragment>);
    }
}
