import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Вітаю!</h1>
            <p>Ви зайшли на оновлену версію сайту <a href='https://FopHelp.pro/'>FopHelp.pro</a>. Цей сайт написаний за допомогою:</p>
        <ul>
          <li><a href='https://get.asp.net/'>ASP.NET Core</a> та <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> для бек-енду</li>
          <li><a href='https://facebook.github.io/react/'>React</a> для відображення</li>
                <li><a href='http://getbootstrap.com/'>Bootstrap</a> та <a href='https://mui.com/'>Material UI</a> для стилізації</li>
        </ul>
        
      </div>
    );
  }
}
