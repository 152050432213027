//const OtherComponent = React.lazy(() => import('./OtherComponent'));
import React, { useState, /*lazy,*/ Fragment, Suspense, useEffect, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
import * as MyTable from "components/Incomes/table"
import ErrorBoundary from 'components/Helpers/Fuse'
import { CookiesAuth } from 'components/Helpers/CookieAuthHelper';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import UserContext from 'components/Auth/UserContext';
import Notification from 'components/Shared/Notification';
const cookieAuth = new CookiesAuth();


const fetchGet = new FetchWithAuth();

const useStyles = makeStyles((theme) => ({
    table: {
      minWidth: 500,
      fontSize: 14
    },
    tableButton: {
        maxWidth: 150,
      },
    tableDate: {
        minWidth: 200,
    },
    tableCurrency: {
        minWidth: 100,
    },
    typography: {
        padding: theme.spacing(2),
      },
  }));


export default function ReportsAll() {
    const anonymousNotificationMessage = "Для перегляду або вводу інформації потрібно авторизуватися";
    const [retrieveStatus, setRetrieveStatus] = useState(anonymousNotificationMessage);
    const [hiddenNotification, setHiddenNotification] = useState(false);
    const [respRep, setRespRep] = useState({});
    const classes = useStyles();
    const { user } = useContext(UserContext);

    var reps = {};
    
    useEffect(() => {
        // Оновлюємо заголовок документа, використовуючи API браузера
        //getData();
        if (user.isAuthenticated) {
            setHiddenNotification(true);
            setRetrieveStatus("");
            getData();
        }
        else {
            setHiddenNotification(false);
            setRetrieveStatus(anonymousNotificationMessage);
        }
        
    }, [user]);

    //_handleButtonClick = () => {
    //function goToDetails(data) {
    //    //calculate your data here
    //    //then redirect:
    //    this.context.router.push({ //browserHistory.push should also work here
    //        pathname: '/reports/details',
    //        state: { repData: data }
    //    });
    //}

      async function getData() {
        if (cookieAuth.getAuthStatus()) {
            setRetrieveStatus("");
            reps = await fetchGet.fetchWithGet("/api/reports/all", null);
            if (!reps)
            {
                reps = {};
                setHiddenNotification(false);
                setRetrieveStatus(anonymousNotificationMessage);
            }
            setRespRep(JSON.parse(reps));
            console.log(JSON.parse(reps));
        }
        
        return reps;
    }

    //// fetch reports from the server
    //async function handleSubmit(event) {
    //    event.preventDefault();
    //    if (cookieAuth.getAuthStatus()) {
    //        setRetrieveStatus("");
    //        reps = await fetchGet.fetchWithGet("/api/reports/all", null);
    //        if (!reps)
    //        {
    //            reps = {};
    //            setRetrieveStatus("Для перегляду інформації потрібно авторизуватися");
    //        }
    //        setRespRep(JSON.parse(reps));
    //        console.log(JSON.parse(reps));
    //    }
        
    //    return reps;
    //}

    ////format date to the form 
    //function formatDate(string){
    //    var options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    //    return new Date(string).toLocaleDateString([],options);
    //}

    //send request to delete expense from the server
    async function doneReport(report) {
        //console.log('going to delete report ID: ' + report.ID);
        //var tmptax = tax;
        //tmpexpense.Expense = expense.Expense.toString();
        //var resp = await fetchGet.fetchWithPost('/api/taxes/delete', report);
        //alert(resp);
        getData();
    }

    //async function ShowMore(report) {
    //    console.log('going to show more for report: ' + report.ID);
    //    //var tmptax = tax;
    //    //tmpexpense.Expense = expense.Expense.toString();
    //    //var resp = await fetchGet.fetchWithPost('/api/taxes/pay', tax);
    //    //alert(resp);
    //    //payedTax = await fetchGet.fetchWithGet("/api/taxes/payed", null);
    //    //getData();
    //    //setRespPayed(JSON.parse(payedTax));
    //    //setRespNotPayed(JSON.parse(notpayedTax));
    //    return (
    //        <Link
    //            to={{
    //                pathname: "/reports/details",
    //                data: report // your data array of objects
    //            }}
    //        />
    //    )
    //}



    return (
        
        <Suspense>

            <Notification isHidden={hiddenNotification} message={retrieveStatus} severity="warning" />

            <div>
                Усі звіти
            </div>

            <div id = "payed-table">
                <ErrorBoundary>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="table with payed taxes" key={"payed-table"}>
                            <TableHead key={'payed-main-header'}>
                                <TableRow key={'payed-header-row'}>
                                    <TableCell>Прибути</TableCell>
                                    <TableCell>Витрати</TableCell>
                                    <TableCell>ЄП заг.</TableCell>
                                    <TableCell>ЄП за п.п.</TableCell>
                                    <TableCell>ЄСВ</TableCell>
                                    <TableCell>ПДВ</TableCell>
                                    <TableCell className={'table-button-tax'} align='center'>Дії</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody key={'reps-table-body'}>
                                {Object.keys(respRep).map((keyName, keyIndex) =>
                                    <Fragment key={'fr-tr-gr-reps-2-'+ keyName}>
                                        <TableRow id={keyName} key={'tr-gr-reps-2-'+ keyName} name={keyName}>
                                            <MyTable.Tdata id={'q-reps-'+keyName} key={'q-reps-'+keyName} name={'q-reps'+keyName} colSpan="7" className='group-row'>{keyName}</MyTable.Tdata>
                                        </TableRow>
                                            {respRep[keyName].map(function (value) {
                                                if(false) {return null}//easier to modify like this
                                                else {
                                                    return (
                                                        <Fragment key={'f-Row-reps' + value.ID}>
                                                            <TableRow key={'Row-reps-' + value.ID} id={value.ID}>
                                                                <TableCell >
                                                                    {value.Incomes}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {value.Expenses}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.FlatTax}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.FlatTaxQ}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.SSP}
                                                                </TableCell>
                                                                <TableCell align='center'>
                                                                    {value.VAT}
                                                                </TableCell>
                                                                <TableCell className={'table-button'} align='center'>
                                                                    <Tooltip title="Детальніше" arrow>
                                                                        <Link
                                                                            to={{
                                                                                pathname: "/reports/details",
                                                                                data: value // your data array of objects
                                                                            }}>
                                                                            <IconButton type="button" variant="outline-warning" ><MoreHorizIcon fontSize="small" /></IconButton >
                                                                        </Link>
                                                                        {/*<IconButton type="button" variant="outline-warning" onClick={(e) => ShowMore(value)}><MoreHorizIcon fontSize="small" /></IconButton >*/}
                                                                    </Tooltip>
                                                                    <Tooltip title="Відмітити як поданий" arrow>
                                                                        <IconButton type="button" variant="outline-warning" onClick={(e) => doneReport(value)}><AssignmentTurnedInOutlinedIcon fontSize="small" /></IconButton >
                                                                    </Tooltip>
                                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                        </Fragment>
                                                    )
                                                }
                                            })}
                                        
                                    </Fragment>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ErrorBoundary>
                
            </div>

            
        </Suspense>

        
    );
}