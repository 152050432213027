import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
//import Cookies from 'universal-cookie';
//import { useHistory } from "react-router-dom";
import * as QueryString from "query-string"
import { useHistory } from "react-router-dom";
//import { Link } from 'react-router';
//const cookies = new Cookies();


export default function Confirm(props) {
    const [state, setState] = useState("");
    //const [password, setPassword, responseString] = useState("");
    //const history = useHistory();
    const query = QueryString.parse(props.location.search);
    const area = query.area;
    const userId = query.userId;
    const code = query.code;
    const history = useHistory();
    //state={responseString: ""};
    console.log(query);
    console.log("area=" + area + "   Id=" + userId + "    code=" + code);
    fetch('/api/react/Authenticate/confirm' + props.location.search, {
        method: 'get',
        // We convert the React state to JSON and send it as the POST body
        //body: JSON.stringify(state)
    }).then(function (response) {
        console.log(response);
        console.log(response.status);

        if (response.status === 200)
        {
            return response.text();
        }
        
        return null;
    }).then((data) => {
        console.log(data)
        setState(data)
      });


    

    return (
        <div className="Login" style={{ textAlign:"center" }}>
            <h5>{state}</h5>
            <Form >
            
                <Button block size="lg" type="submit" onClick={() => history.push("/auth/login")}>
                    Увійти
                </Button>
            </Form>
        </div>
    );
}
