import { Height } from '@material-ui/icons';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FetchWithAuth } from 'components/Helpers/FetchAuthHelper';
import { CookiesAuth } from 'components/Helpers/CookieAuthHelper';
import Checkbox from '@material-ui/core/Checkbox';

var repDetail = null;
const fetchGet = new FetchWithAuth();
const cookieAuth = new CookiesAuth();

export class DetailedReport extends Component {
    static displayName = DetailedReport.name;

    constructor(props) {
        super(props);
        //this.state = { forecasts: [], loading: true };
        this.state = {
            report: props.location.data,
            retrieveStatus: "",
            repDetail: {
                date: "",
                quarter: 0,
                data: []
            },
            q1: false,
            q2: false,
            q3: false,
            q4: false,

        }
        //repDetail = {
        //    date: "",
        //    quarter: 0,
        //    data: []
        //}
        console.log(props, this.state.report);
        //repDetail = this.state.report;
    }

    async getData(rep) {
        if (cookieAuth.getAuthStatus()) {
            this.setState({ retrieveStatus: "" });
            var reps = await fetchGet.fetchWithPost("/api/reports/details", rep);
            if (!reps) {
                reps = null;
                this.setState({ retrieveStatus: "Для перегляду інформації потрібно авторизуватися" });
            }
            //this.setState({ report: JSON.parse(reps) });
            console.log(reps);
        }
        return reps;
    }

    async componentDidMount() {
        var reps = await this.getData(this.state.report);
        this.setState({ repDetail: reps });
        if (this.state.repDetail) {
            if (this.state.repDetail.quarter == 1) {
                this.setState({ q1: true });
            }
            else if (this.state.repDetail.quarter == 2) {
                this.setState({ q2: true });
            }
            else if (this.state.repDetail.quarter == 3) {
                this.setState({ q3: true });
            }
            else if (this.state.repDetail.quarter == 4) {
                this.setState({ q4: true });
            }
        }
        
        console.log(this.state.repDetail);
    }


    

    render() {
        if (this.state.repDetail) {
            return (
                <div id="report">
                    <table id="report-01">
                        <tbody>
                            <tr id="row-01" style={{ width: '680px' }} >
                                <td width="30px" style={{ border: '1px solid' }}>
                                    01
                                </td>
                                <td width="400px" style={{ border: '1px solid' }}>
                                    <h6>
                                        ОДАТКОВА ДЕКЛАРАЦІЯ ПЛАТНИКА ЄДИНОГО ПОДАТКУ –
                                        ФІЗИЧНОЇ ОСОБИ – ПІДПРИЄМЦЯ
                                    </h6>
                                </td>
                                <td width="250px" style={{ border: '1px solid' }}>
                                    <table style={{ fontSize: '12px' }}>
                                        <tbody>
                                            <tr>
                                                <td width="30px" style={{ border: '1px solid' }}>01</td>
                                                <td width="30px" style={{ border: '1px solid' }}><input type="checkbox" id="row3-Q1" checked onChange={(e) => null} /></td>
                                                <td width="190px" style={{ border: '1px solid' }}>Звітна</td>
                                            </tr>
                                            <tr>
                                                <td width="30px" style={{ border: '1px solid' }}>02</td>
                                                <td width="30px" style={{ border: '1px solid' }}><input type="checkbox" id="row3-Q1" disabled /></td>
                                                <td width="190px" style={{ border: '1px solid' }}>Звітна нова</td>
                                            </tr>
                                            <tr>
                                                <td width="30px" style={{ border: '1px solid' }}>03</td>
                                                <td width="30px" style={{ border: '1px solid' }}><input type="checkbox" id="row3-Q1" disabled /></td>
                                                <td width="190px" style={{ border: '1px solid' }}>Уточнююча</td>
                                            </tr>
                                            <tr>
                                                <td width="30px" style={{ border: '1px solid' }}>04</td>
                                                <td width="30px" style={{ border: '1px solid' }}><input type="checkbox" id="row3-Q1" disabled /></td>
                                                <td width="190px" style={{ border: '1px solid' }}>Довідково</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '5px' }}></tr>
                        </tbody>

                    </table>
                    <table id="report-02">
                        <tbody>
                            <tr id="row-02" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    02
                                </td>
                                <td width="650px" style={{ border: '1px solid' }}>
                                    <table style={{ fontSize: '12px' }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan="6">
                                                    Податковий звітний період
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="100px">

                                                    
                                                    <Checkbox type="checkbox" key={'q1'}
                                                        id={'q1'}
                                                        size='small'
                                                        checked={this.state.q1}
                                                        color="primary"
                                                        onChange={(e) => null}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                                    </Checkbox> I квартал


                                                </td>
                                                <td width="100px">
                                                    <Checkbox type="checkbox" key={'q2'}
                                                        id={'q2'}
                                                        size='small'
                                                        checked={this.state.q2}
                                                        color="primary"
                                                        onChange={(e) => null}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                                    </Checkbox>Півріччя

                                                </td>
                                                <td width="130px">
                                                    <Checkbox type="checkbox" key={'q3'}
                                                        id={'q3'}
                                                        size='small'
                                                        checked={this.state.q3}
                                                        color="primary"
                                                        onChange={(e) => null}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                                    </Checkbox>три квартали

                                                </td>
                                                <td width="100px">
                                                    <Checkbox type="checkbox" key={'q4'}
                                                        id={'q4'}
                                                        size='small'
                                                        checked={this.state.q4}
                                                        color="primary"
                                                        onChange={(e) => null}
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }} >
                                                    </Checkbox> рік

                                        </td>
                                                <td width="100px">

                                                </td>
                                                <td width="150px" style={{ color: 'blue', fontWeight: 'bold' }}>
                                                    {this.state.repDetail.date }  року
                                        </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    (необхідне позначити)
                                    </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '5px' }}></tr>
                        </tbody>
                    </table>
                    <table id="report-03">
                        <tbody>
                            <tr id="row-03" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    03
                    </td>
                                <td width="650px" style={{ border: '1px solid' }}>
                                    <table style={{ fontSize: '12px' }}>
                                        <tbody>
                                            <tr>
                                                <td colSpan="6">
                                                    Податковий (звітний) період,  який уточнюється
                                    </td>
                                            </tr>
                                            <tr>
                                                <td width="100px">
                                                    <input type="checkbox" id="row3-Q1" disabled /> I квартал

                                        </td>
                                                <td width="100px">
                                                    <input type="checkbox" id="row3-Q2" disabled /> Півріччі
                                        </td>
                                                <td width="130px">
                                                    <input type="checkbox" id="row3-Q3" disabled /> IІІ квартали
                                        </td>
                                                <td width="100px">
                                                    <input type="checkbox" id="row3-Q4" disabled /> Рік
                                        </td>
                                                <td width="100px">

                                                </td>
                                                <td width="150px">
                                                    20.. ..  року
                                        </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    (необхідне позначити)
                                        </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '5px' }}></tr>
                        </tbody>
                    </table>
                    <table id="report-04">
                        <tbody>
                            <tr id="row-04" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    04
                        </td>
                                <td width="650px" style={{ border: '1px solid' }}>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '14px' }}>
                                <td colSpan="2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                    (найменування контролюючого органу, до якого подається звітність)
                        </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="report-05">
                        <tbody>
                            <tr id="row-05" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    05
                        </td>
                                <td width="650px" style={{ border: '1px solid' }}>
                                    <table width="650px">
                                        <tbody>
                                            <tr style={{ border: '1px solid', width: '650px', Height: '22px' }}>
                                                <td>.</td>
                                            </tr>
                                            <tr style={{ border: '1px solid', width: '650px', Height: '22px' }}>
                                                <td>.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '14px' }}>
                                <td colSpan="2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                    (прізвище, ім'я, по батькові платника податків згідно з реєстраційними документами)
                        </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="report-06">
                        <tbody>
                            <tr id="row-06" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    06
                        </td>
                                <td width="650px" style={{ border: '1px solid' }} >
                                    <table width="650px">
                                        <tbody>
                                            <tr style={{ border: '1px solid', width: '650px', Height: '22px' }}>
                                                <td>.</td>
                                            </tr>
                                            <tr style={{ border: '1px solid', width: '650px', Height: '22px' }}>
                                                <td>.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '14px' }}>
                                <td colSpan="2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                    (податкова адреса (місце проживання) платника податку)
                        </td>
                            </tr>
                        </tbody>
                    </table >
                    <table id="report-07">
                        <tbody>
                            <tr id="row-07" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    07
                        </td>
                                <td width="450px" style={{ border: '1px solid' }} >
                                </td>
                                <td width="200px" style={{ border: '1px solid' }}>
                                    <table>
                                        <tbody>
                                            <tr style={{ height: '44px' }}>

                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                                <td style={{ border: '1px solid', width: '20px', Height: '44px' }}>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table >
                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>І. ЗАГАЛЬНІ ПОКАЗНИКИ ПІДПРИЄМНИЦЬКОЇ ДІЯЛЬНОСТІ</h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="report-08">
                        <tbody>
                            <tr id="row-08" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    08
                        </td>
                                <td width="470px" style={{ border: '1px solid', fontSize: '12px' }}>
                                    Фактична чисельність найманих працівників у звітному періоді (осіб)
                        </td>
                                <td width="180px" style={{ border: '1px solid' }}>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="report-09">
                        <tbody>
                            <tr id="row-09" style={{ width: '680px' }}>
                                <td width="30px" style={{ border: '1px solid' }}>
                                    09
                        </td>
                                <td width="650px" style={{ border: '1px solid', fontSize: '12px' }}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td colSpan="2" style={{ border: '1px solid', fontSize: '12px' }}>
                                                    Види підприємницької діяльності у звітному періоді ²:
                                        </td>
                                            </tr>
                                            <tr>
                                                <td width="100px" style={{ border: '1px solid', fontSize: '12px', textAlign: 'center' }}>Код згідно з КВЕД</td>
                                                <td width="550px" style={{ border: '1px solid', fontSize: '12px', textAlign: 'center' }}>Назва згідно з КВЕД   </td>
                                            </tr>
                                            <tr>
                                                <td width="100px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                                <td width="550px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                            </tr>
                                            <tr>
                                                <td width="100px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                                <td width="550px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                            </tr>
                                            <tr>
                                                <td width="100px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                                <td width="550px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                            </tr>
                                            <tr>
                                                <td width="100px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                                <td width="550px" style={{ border: '1px solid', fontSize: '12px' }} height="22px"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td colSpan="4" style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>
                                        ІІ. ПОКАЗНИКИ ГОСПОДАРСЬКОЇ ДІЯЛЬНОСТІ ДЛЯ ПЛАТНИКІВ
                                        ЄДИНОГО ПОДАТКУ  ПЕРШОЇ   ГРУПИ
                            </h6>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    Щомісячні авансові внески, грн, коп.
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    І  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІI  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІII  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІV  квартал
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                            </tr>
                        </tbody>
                    </table>

                    <table id="II-chapter-data">
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'center' }}>
                                    Назва показника
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    Код рядка
                        </td>
                                <td style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    Обсяг (грн, коп.)³
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу за звітний (податковий) період відповідно до статті 292 глави 1 розділу XIV Податкового кодексу України (згідно з підпунктом 1 пункту 291.4 статті 291 глави 1 розділу XIV Податкового кодексу України)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    01
                        </td>
                                <td id="row-code-data-02" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>
                                        {this.state.repDetail.data[1]}
                                    </span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу, що оподаткований за ставкою 15 відсотків (згідно з пунктом 293.4 статті 293 глави 1 розділу XIV Податкового кодексу України), у звітному (податковому) періоді⁴
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    02
                        </td>
                                <td id="row-code-data-02" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}> {this.state.repDetail.data[2]} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td colSpan="4" style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>
                                        ІІІ. ПОКАЗНИКИ ГОСПОДАРСЬКОЇ ДІЯЛЬНОСТІ ДЛЯ ПЛАТНИКІВ ЄДИНОГО ПОДАТКУ ДРУГОЇ  ГРУПИ
                            </h6>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    Щомісячні авансові внески, грн, коп.
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    І  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІI  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІII  квартал
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }}>
                                    ІV  квартал
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                                <td style={{ border: '1px solid', width: '170px', textAlign: 'center' }} height="22px">.
                        </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="III-chapter-data">
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'center' }}>
                                    Назва показника
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    Код рядка
                        </td>
                                <td style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    Обсяг (грн, коп.)³
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу за звітний (податковий) період відповідно до статті 292 глави 1 розділу XIV Податкового кодексу України (згідно з підпунктом 1 пункту 291.4 статті 291 глави 1 розділу XIV Податкового кодексу України)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    03
                        </td>
                                <td id="row-code-data-03" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}> {this.state.repDetail.data[3]} </span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу, що оподаткований за ставкою 15 відсотків (згідно з пунктом 293.4 статті 293 глави 1 розділу XIV Податкового кодексу України), у звітному (податковому) періоді⁴
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    04
                        </td>
                                <td id="row-code-data-04" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}> {this.state.repDetail.data[4]} </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td colSpan="4" style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>
                                        ІV. ПОКАЗНИКИ ГОСПОДАРСЬКОЇ ДІЯЛЬНОСТІ ДЛЯ ПЛАТНИКІВ ЄДИНОГО ПОДАТКУ  ТРЕТЬОЇ  ГРУПИ
                            </h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="IV-chapter-data">
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'center' }}>
                                    Назва показника
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    Код рядка
                        </td>
                                <td style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    Обсяг (грн, коп.)³
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу за звітний (податковий) період, що оподатковується за ставкою 3%
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    05
                        </td>
                                <td id="row-code-data-05" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}> {this.state.repDetail.data[5]} </span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу за звітний (податковий) період, що оподатковується за ставкою 5%
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    06
                        </td>
                                <td id="row-code-data-06" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}> {this.state.repDetail.data[6]} </span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Обсяг доходу, що оподаткований за ставкою 15 відсотків (згідно  з пунктом 293.4 статті 293 глави 1 розділу XIV Податкового кодексу України), у звітному (податковому) періоді⁴
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    07
                        </td>
                                <td id="row-code-data-07" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[7]}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td colSpan="4" style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>
                                        V.  ВИЗНАЧЕННЯ ПОДАТКОВИХ ЗОБОВ’ЯЗАНЬ ПО ЄДИНОМУ ПОДАТКУ⁵
                            </h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="V-chapter-data">
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'center' }}>
                                    Назва показника
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    Код рядка
                        </td>
                                <td style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    Обсяг (грн, коп.)³
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Загальна сума доходу за звітний (податковий) період (сума значень рядків 01+02+03+04+05+06+07)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    08
                        </td>
                                <td id="row-code-data-08" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[8]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума податку за ставкою 15 % ((рядок 02 + рядок 04 + рядок 07) × 15%)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    09
                        </td>
                                <td id="row-code-data-09" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[9]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума податку за ставкою 3 % (рядок  05 × 3 %)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    10
                        </td>
                                <td id="row-code-data-10" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[10]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума податку за ставкою 5 % (рядок 06 × 5 %)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    11
                        </td>
                                <td id="row-code-data-11" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[11]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Нараховано всього за звітний (податковий) період (рядок 9 + рядок 10 + рядок 11)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    12
                        </td>
                                <td id="row-code-data-12" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[12]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Нараховано за попередній звітний (податковий) період (значення рядка 12 декларації  попереднього звітного (податкового) періоду)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    13
                        </td>
                                <td id="row-code-data-13" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[13]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума єдиного податку, яка підлягає нарахуванню та сплаті в бюджет за підсумками поточного звітного (податкового) періоду (рядок 12 - рядок 13)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    14
                        </td>
                                <td id="row-code-data-14" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[14]}</span>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', Height: '14px' }}>
                                <td colSpan="4" style={{ fontSize: '12px', textAlign: 'center', alignCenter: 'center', width: '680px' }}>
                                    <h6>
                                        VІ. ВИЗНАЧЕННЯ ПОДАТКОВИХ ЗОБОВ'ЯЗАНЬ У ЗВ'ЯЗКУ З ВИПРАВЛЕННЯМ САМОСТІЙНО ВИЯВЛЕНИХ ПОМИЛОК⁶
                            </h6>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table id="VI-chapter-data">
                        <tbody>
                            <tr style={{ border: '1px solid', width: '680px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'center' }}>
                                    Назва показника
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    Код рядка
                        </td>
                                <td style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    Обсяг (грн, коп.)³
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Збільшення суми, яка підлягала перерахуванню до бюджету  (рядок 16 - рядок 15, якщо рядок 16 > рядка 15)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    15
                        </td>
                                <td id="row-code-data-15" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[15]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Уточнена сума податкових зобов'язань за звітний (податковий) період, у якому виявлена помилка
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    16
                        </td>
                                <td id="row-code-data-16" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[16]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td colSpan="3" style={{ border: '1px solid', fontWeight: 'bold', textAlign: 'center' }} width="680px">
                                    Розрахунки у зв'язку з виправленням помилки:
                        </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Збільшення суми, яка підлягала перерахуванню до бюджету  (рядок 16 - рядок 15, якщо рядок 16 > рядка 15)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    17
                        </td>
                                <td id="row-code-data-17" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[17]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Зменшення суми, яка підлягала перерахуванню до бюджету⁷
                                    (рядок 16 - рядок 15, якщо рядок 16 менше рядка 15)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    18
                        </td>
                                <td id="row-code-data-18" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[18]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Нараховано за попередній звітний (податковий) період (значення рядка 12 декларації  попереднього звітного (податкового) періоду)
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    19
                        </td>
                                <td id="row-code-data-19" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[19]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума штрафу, яка нарахована платником податку самостійно у зв'язку з виправленням помилки,
                                    ___ % (рядок 17 × 3 % або  17 × 5 %)⁸
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    20
                        </td>
                                <td id="row-code-data-20" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[20]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: '1px solid', width: '680px', Height: '22px', fontSize: '12px' }}>
                                <td style={{ border: '1px solid', width: '450px', textAlign: 'left' }}>
                                    Сума пені, яка нарахована платником податку самостійно відповідно до підпункту
                                    129.1.2 пункту 129.1 статті 129 глави 12 розділу ІІ Податкового кодексу України
                        </td>
                                <td style={{ border: '1px solid', width: '40px', textAlign: 'center', fontSize: '12px' }}>
                                    21
                        </td>
                                <td id="row-code-data-14" style={{ border: '1px solid', width: '190px', textAlign: 'center' }}>
                                    <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.repDetail.data[21]}</span>
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '14px' }}>
                                <td colSpan="2" style={{ fontSize: '12px', textAlign: 'center' }}>.
                        </td>
                            </tr>
                        </tbody>
                    </table>

                    <table id="report-04">
                        <tbody>
                            <tr id="row-04" style={{ width: '680px' }}>
                                <td width="40px" style={{ border: '1px solid' }}>
                                </td>
                                <td width="640px" style={{ border: '1px solid', fontWeight: 'bold', fontSize: '12px' }}>
                                    Доповнення до податкової декларації (заповнюється і додається відповідно до пункту 46.4 статті 46 глави 2 розділу ІІ Податкового кодексу України)        на                 арк.
                        </td>
                            </tr>
                            <tr id="row-04" style={{ width: '680px' }}>
                                <td width="40px" style={{ border: '1px solid', fontSize: '12px' }}>
                                    № з/п
                        </td>
                                <td width="640px" style={{ border: '1px solid', fontSize: '12px' }}>
                                    Зміст доповнення
                        </td>
                            </tr>
                            <tr id="row-04" style={{ width: '680px' }}>
                                <td width="40px" style={{ border: '1px solid' }} height="22px">
                                </td>
                                <td width="640px" style={{ border: '1px solid' }} height="22px">
                                </td>
                            </tr>
                            <tr style={{ border: 'none', Height: '14px' }}>
                                <td colSpan="2" style={{ fontSize: '12px', textAlign: 'center' }}>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr style={{ width: '680px', Height: '14px' }}>
                                <td style={{ textAlign: 'left', fontSize: '12px' }} width="680px">
                                    <br />
                                    <pre>Дата подання декларації:          _ _ . _ _ . _ _ _ _</pre>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table style={{ border: '1px solid' }}>
                        <tbody>
                            <tr style={{ width: '680px', Height: '14px' }}>
                                <td style={{ textAlign: 'left', fontSize: '12px' }} width="680px">
                                    Прізвище, ім'я, по батькові уповноваженої особи: ______________________________________________________________________
                        </td>
                            </tr>
                            <tr style={{ width: '680px', Height: '14px' }}>
                                <td style={{ textAlign: 'left', fontSize: '12px' }} width="680px">
                                    Реєстраційний номер облікової картки платника податків    __ __ __ __ __ __ __ __ __ __
                        </td>
                            </tr>
                            <tr style={{ width: '680px', Height: '14px' }}>
                                <td style={{ textAlign: 'left', fontSize: '12px' }} width="680px">
                                    або серія та номер паспорта (1)
                        </td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr >
                                <td width="680px" style={{ fontSize: '12px' }}>
                                    *   Подається з метою отримання  довідки про доходи за  інший період, ніж  квартальний (річний) податковий (звітний) період. <br /><br />

                    ** Зазначається номер календарного місяця, за який подається декларація з позначкою  “Довідково”.<br /><br />

                    ¹ Серію та номер паспорта зазначають фізичні особи, які мають відмітку у паспорті про право здійснювати  будь-які платежі за серією та  номером паспорта.
                    облікової картки платника податків та повідомили про це відповідний контролюючий орган і мають відмітку в паспорті про право
                    здійснювати будь-які платежі за серією та номером паспорта.<br /><br />

                    ² Код  та назва виду економічної  діяльності зазначаються відповідно до Класифікатора видів економічної діяльності (КВЕД).<br /><br />

                    ³ Заповнюється наростаючим підсумком з початку року у гривнях з двома десятковими знаками після коми.<br /><br />

                    ⁴ Включається:<br />

                    сума доходу, що перевищує обсяги, встановлені підпунктами 1, 2, 3  пункту 291.4 статті 291 глави 1 розділу XIV Податкового кодексу України, у звітному (податковому) періоді;<br />
                    сума доходу, отриманого від провадження діяльності, не зазначеної в реєстрі платників єдиного податку (для першої або другої групи), у звітному (податковому) періоді;<br />
                    сума доходу, отриманого при застосуванні іншого способу розрахунків, ніж передбачено пунктом 291.6 статті 291 глави 1 розділу XIV Податкового кодексу України, у звітному (податковому) періоді;<br />
                    сума доходу, отриманого від здійснення видів діяльності, які не дають права на застосування спрощеної системи оподаткування, у звітному (податковому) періоді;<br />
                    сума доходу, отриманого платниками першої або другої групи від провадження діяльності, яка не передбачена в підпункті 1 або 2 пункту 291.4 статті 291 Кодексу.<br /><br />

                    ⁵ Заповнюється наростаючим підсумком з початку року (не заповнюється платником податку, що подає декларацію „Довідково”).<br /><br />

                    ⁶ Заповнюється платником податку, який уточнює податкові зобов’язання (не заповнюється платником податку, що подає декларацію «Довідково»). Для уточнення звітних періодів, що передували періоду запровадження нової форми декларації, у рядку 15 зазначається сума єдиного податку, яка підлягала перерахуванню до бюджету, за даними звітного (податкового) періоду, в якому виявлена помилка (рядок 29 податкової декларації, затвердженої наказом Міністерства фінансів України від 21 грудня 2011 року № 1688 «Про затвердження форм податкових декларацій платника єдиного податку», зареєстрованим у Міністерстві юстиції України 27 грудня 2011 року за № 1533/20271 (у редакції наказу Міністерства фінансів України від 07 листопада 2012 року № 1159)).
                    <br /><br />
                    ⁷ Зазначається тільки як позитивне значення.
                    <br /><br />
                    ⁸ Нараховується платником у разі самостійного виявлення факту заниження податкового зобов’язання (пункт 50.1 статті 50 глави 2 розділу ІІ Податкового кодексу України).

                    </td>
                            </tr>
                        </tbody>
                    </table>


                </div >
            );
        }
        else {
            return (
                <Link
                    to={{
                        pathname: "/reports/all"
                    }}>
                    Назад у звіти
                </Link>
                );
        }
  }
}
