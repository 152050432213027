import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function Notification(props) {
    const [hidden, setHidden] = useState(props.isHidden)
    //const [message, setMessage] = useState(props.message)
    //const [severity, setSeverity] = useState(props.severity)

    useEffect(() => {
        if (props.isHidden) {
            setHidden(true);
        }
        else {
            setHidden(false);
        }
    }, [props]);

    console.log(props);
    if (!!props.message) {
        return (
            <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity={props.severity} onClose={() => { setHidden(true) }} hidden={hidden}>{props.message}</Alert>
            </Stack>
        );
    }
    else {
        return (
            <div></div>
        )
    }
}