import React from 'react';

export  function Table(props) {
    return (
      <div>
      <table> 
        {props.children}
      </table>
    </div>
    );
  }

  export function Header(props) {
    return (
      <thead> 
        {props.children}
      </thead>
    );
  }

  export function Body(props) {
    return (
      <tbody> 
      {props.children}
    </tbody>
    );
  }

  export function Row(props) {
    return (
      <tr> 
        {props.children}
      </tr>
    );
  }

  export function Tdata(props) {
      var colspan;
      if (props.colSpan)
      {
          colspan=props.colSpan;
      }
      else {
          colspan=1;
      }
    return (
      <td colSpan={colspan} className={props.className}> 
        {props.children}
      </td>
    );
  }

  export function Thead(props) {
    return (
      <th> 
        {props.children}
      </th>
    );
  }
  
  export function Input(props) {
    return (
      <input type="text" value={props.value}/>
    );
  }