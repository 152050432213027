import React, { Component } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export class CookiesAuth extends Component {
    static displayName = CookiesAuth.name;

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            
        };
        this.incrementCounter = this.incrementCounter.bind(this);
        //this.fetchWithGet = this.fetchWithGet.bind(this);
    }
    //SAFE_componentWillMount() {
    //    this.fetchWithGet = this.fetchWithGet.bind(this);
    //}

   


    incrementCounter() {
        this.setState({
             currentCount: this.state.currentCount + 1
        });
    }

    getCookieUser() {
        if (cookies.get('Session-User')) {
            var user = cookies.get('Session-User');
            if (user !== null && user !== undefined) {
                console.log("get user " + user)
                return user;
            }
        }
        else {
            return null;
        }
        
    }

    getCurrentAuth() {
        if (this.getCookieUser() != null) {
            console.log(this.getCookieUser());
            return [true, this.getCookieUser];
        }
        else {
            return [false, null];
        }
    }

    getAuthStatus() {
        if (this.getCookieUser() != null) {
            
            return true;
        }
        else {
            return false;
        }
    }
    

    

  render() {
    return (
      <div>
        <h1>Counter</h1>

        <p>This is a simple example of a React component.</p>

        <p aria-live="polite">Current count: <strong>{this.state.currentCount}</strong></p>

        <button className="btn btn-primary" onClick={this.incrementCounter}>Increment</button>
      </div>
    );
  }
}
